<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('RMS') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div class="column is-narrow">
            <Modal :mask="true">
              <template #default="{ hide }">
                <RMSAddModal
                  :value="{}"
                  @close="
                    () => {
                      hide();
                      fetchData();
                    }
                  "
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <button
                  v-permission="['modify-rms']"
                  class="ant-btn ant-btn-lg ant-btn-primary"
                  @click.prevent="show"
                >
                  {{ $t('Add New RMS') }}
                </button>
              </template>
            </Modal>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="rmsSettings"
          :custom-row="rowClicked"
          :loading="isFetching"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #property="{ record }">
            {{ record.property ? record.property.name : record.propertyId }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "RMS": "RMS",
    "Add New RMS": "Add New RMS",
    "RMS Name": "RMS Name",
    "Currency": "Currency",
    "Status": "Status",
    "active": "active",
    "inactive": "inactive",
  },
  "ja": {
    "RMS": "RMS",
    "Add New RMS": "新しいRMSを追加",
    "RMS Name": "RMS名称",
    "Currency": "通貨",
    "Status": "ステータス",
    "active": "有効",
    "inactive": "無効",
  }
}
</i18n>

<script>
import pagination from '@/components/mixins/pagination';
import SearchForm from './components/SearchForm';
import Modal from '@/components/Modal';
import RMSAddModal from './components/overview/RmsForm';
import attachProperties from '../mixins/attachProperties';

export default {
  name: 'Users',
  components: {
    SearchForm,
    Modal,
    RMSAddModal,
  },
  mixins: [pagination, attachProperties],
  data() {
    return {
      rmsSettings: undefined,
      isFetching: false,
      columns: [
        {
          title: this.$t('RMS Name'),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: this.$t('Property'),
          dataIndex: 'propertyId',
          key: 'propertyId',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Status'),
          dataIndex: 'status',
          key: 'status',
          customRender: ({ record }) => this.$t(record.status),
        },
        {
          title: this.$t('Currency'),
          key: 'currency',
          dataIndex: 'currency',
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      try {
        const { data, pagination } = await this.$store.dispatch(
          'rms-settings/listRms',
          this.$route.query,
        );

        this.rmsSettings = await this.attachProperties(data);

        this.setPagination(pagination);
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$store.dispatch('routers/addFirstLevelQuery', {
            query: this.$route.query,
            name: 'revenue-management-settings',
          });
          this.$router.push({ name: 'revenue-management-settings-overview', params: { id: record.id } });
        },
      };
    },
  },
};
</script>
